img{ vertical-align: top;}
div:where(.swal2-container) button:where(.swal2-styled).swal2-confirm:focus {
    box-shadow: 0 0 0 0 rgba(65,90,147,0) !important;
}
div:where(.swal2-container) button:where(.swal2-styled).swal2-confirm {
    background-color: #2d3a58 !important;
}
div:where(.swal2-container) {
    z-index: 5000 !important;
}
div:where(.swal2-container) h2:where(.swal2-title) { font-size: 24px !important;}
div:where(.swal2-container) .swal2-html-container { font-size: 16px !important;}
.swiper-pagination-bullets.swiper-pagination-horizontal {bottom: 0 !important;}

input[type=text].default,input[type=number].default,input[type=email].default,input[type=tel].default{ width:calc(100% - 15px); padding: 5px 0 0 15px; border: 0; height: 40px; line-height: 40px; border-bottom: solid 1px #e1e1e1; font-size: 15px !important; outline: none;  transition: 0.3s; }
input[type=text].default::placeholder,input[type=number].default::placeholder,input[type=email].default,input[type=tel].default::placeholder{ font-size: 14px; line-height: 40px; color: #999;}
input[type=text].default:focus,input[type=number].default:focus,input[type=email].default:focus,input[type=tel].default:focus{ border-bottom: 1px solid #6F2089;}
input[type=text]:disabled,input[type=number]:disabled,input[type=email]:disabled,input[type=tel]:disabled{ background-color: #fff; color: #ccc;}
.inputBtn{ position: absolute; bottom: 5px; right: 0; width:76px; height: 36px; line-height: 36px; border-radius: 36px; border: 0; background-color: #6F2089; color: #fff; text-align: center; font-size: 13px; transition: 0.3s;}
.inputBtn:disabled{ background-color: #ccc;}
.certifyNum{ position: absolute; bottom:2px; right: 84px; line-height: 40px; font-size: 12px; color: #D4B5FF;}
button.default{ width:100%; height: 46px; line-height: 46px; border-radius: 46px; text-align: center; border:0; background-color: #6F2089; color: #fff; font-size: 16px !important; transition: 0.3s;}
button.default:disabled{ background-color: #ccc;}
button.minWidth{ display: inline-table; padding: 0 20px 0 20px; height: 46px; line-height: 46px; border-radius: 46px; text-align: center; border:0; background-color: #6F2089; color: #fff; font-size: 16px !important; transition: 0.3s;}
button.minWidth:disabled{ background-color: #ccc;}

.notFoundWrap{ position: absolute; top:50%; left: 50%; width: 250px; transform: translate(-50%,-55%); text-align: center;}
.notFoundWrap .ani{ width:250px; height: 250px; margin: 0 auto;}
.notFoundWrap .title{ font-family: 'Bold',sans-serif; font-size: 40px;}
.notFoundWrap .tit{ font-size: 18px;}
.notFoundWrap .txt{ padding: 30px 0 0 0; font-size: 14px; opacity: 0.5;}

.sbox{ position: relative; margin: 10px 10px 0 10px; border-radius: 10px; background-color: #fff; padding: 15px; box-shadow:0 0 3px rgba(0, 0, 0, .05);}
.titleDefault{ font-size: 18px; font-family: 'Bold', sans-serif; letter-spacing: -0.5px;}

.setIcon_weight{ background: url(asset/images/ico_diary_weight.svg); background-size: contain;}
.setIcon_defecation{ background: url(asset/images/ico_diary_defecation.svg); background-size: contain;}
.setIcon_memo{ background: url(asset/images/ico_diary_memo.svg); background-size: contain;}
.setIcon_todo{ background: url(asset/images/ico_diary_todo.svg); background-size: contain;}
.setIcon_walk{ background: url(asset/images/ico_diary_walk.svg); background-size: contain;}
.setIcon_play{ background: url(asset/images/ico_diary_play.svg); background-size: contain;}
.setIcon_eat{ background: url(asset/images/ico_diary_eat.svg); background-size: contain;}
.setIcon_water{ background: url(asset/images/ico_diary_water.svg); background-size: contain;}
.setIcon_treatment{ background: url(asset/images/ico_diary_treatment.svg); background-size: contain;}
.setIcon_symptom{ background: url(asset/images/ico_diary_symptom.svg); background-size: contain;}

.topBar{ position: fixed; top:0; left: 0; width:100%; height: 60px; background-color: #fff; z-index: 700;}
.topBar .logo{ height: 30px; margin: 15px 0 0 15px;}
.topBar .alert{ position: absolute; top:16px; right: 15px; font-size: 24px; color: #999;}
.topBar .alert.new::before{ content: ''; position: absolute; top:3px; right: -3px; width:8px; height: 8px; border-radius: 8px; border: solid 2px #fff; background-color: #D4B5FF;}
.topBarBlank{ height: 60px;}

.botMenu{ position: fixed; bottom: 0; left: 0; display: flex; background-color: #fff; width:100%; height: 50px; text-align: center; box-shadow:0 -1px 1px rgba(0, 0, 0, .05); z-index: 700;}
.botMenu ul{ position: relative; width:20%; font-size: 12px; color: #c1c1c1; user-select: none;}
.botMenu ul a{ color: #c1c1c1;}
.botMenu ul .ico{ position: absolute; bottom: 0; left: calc(50% - 25px); width:50px; height: 50px; border-radius: 50px; background-color: #fff; transition: 0.3s;}
.botMenu ul i{ font-size: 20px; line-height: 50px;}
.botMenu ul .txt{ position: absolute; bottom: 6px; left:0; color: #6F2089; height: 20px; line-height: 20px; border-radius: 20px; width:100%; transition: 0.3s; opacity: 0;}
.botMenu ul.on a{ color: #6F2089;}
.botMenu ul.on .ico{ bottom: 20px; box-shadow:0 -1px 2px rgba(0, 0, 0, .1);}
.botMenu ul.on .txt{ opacity: 1;}
.botMenuBlank{ height: 75px;}

.slideMenu{}
.slideMenu .inBox{ position: fixed; top:0; right: -100%; width:85%; height: 100%; background-color: #fff; border-radius: 20px 0 0 20px; transition: right 0.5s ease; z-index: 800;}
.slideMenu .bg{ position: fixed; top:0; right: 0; width:100%; height: 100%; background-color: rgba(0,0,0,0.5); display: none; z-index: 750;}
.slideMenu.open .bg{ display: block;}
.slideMenu.open .inBox{ right: 0;}
.slideMenu .inBox .myInfo{ position: relative; padding: 15px; border-bottom: solid 1px #eee;}
.slideMenu .inBox .myInfo .profileImage{ position: absolute; top:15px; left: 15px; width:60px; height: 60px; border-radius: 60px; overflow: hidden; background-color: #f8f8f8;}
.slideMenu .inBox .myInfo .profileImage img{ position: absolute; top:0; left: 0; width:100%; height: 100%; object-fit: cover;}
.slideMenu .inBox .myInfo ul{ position: relative; display: flex; align-items: center; margin: 7px 0 0 70px;}
.slideMenu .inBox .myInfo ul .nickName{ font-size: 17px;}
.slideMenu .inBox .myInfo ul .nickName span{ font-family: 'Bold', sans-serif;}
.slideMenu .inBox .myInfo ul .authentication{ background-color:#f8f8f8; color: #999; padding: 5px 10px 5px 10px; border-radius: 50px; font-size: 12px; margin: 0 0 0 10px;}
.slideMenu .inBox .myInfo ul li{ margin: 0 0 0 10px; color: #999;}
.slideMenu .inBox .myInfo ul li span{ color: #6F2089; font-family: 'Bold', sans-serif; margin: 0 0 0 3px;}
.slideMenu .inBox .myInfo ul li:nth-child(1){ margin: 0 0 0 0;}
.slideMenu .inBox .myInfo .arw{ position: absolute; top:35px; right: 15px; font-size: 20px; color: #ccc;}
.slideMenu .inBox .menu{ position: absolute; top:91px; left: 0; width:100%; height: calc(100% - 141px); overflow-y: auto;}
.slideMenu .inBox .menu ul{ position: relative; height: 50px; line-height: 50px; font-size: 16px; margin: 0 0 0 70px; border-bottom: dashed 1px #eee; padding: 0 0 0 10px;}
.slideMenu .inBox .menu ul i{ position: absolute; top:0; left: -30px; line-height: 50px; font-size: 20px; color: #999;}
.slideMenu .inBox .bot{ position: absolute; bottom: 0; left: 0; width:100%; text-align: center; height: 80px;}
.slideMenu .inBox .bot .logout{ color: #ccc;}
.slideMenu .inBox .bot .version{ padding: 15px 0 10px 0; color: #999;}

.layerPop{}
.layerPop .bg{ position: fixed; top:0; left: 0; width:100%; height: 100%; background-color: rgba(0,0,0,0.6); z-index: 900;}
.layerPop .inBox{ animation: layerpop 0.5s forwards; position: fixed; top:70%; left: 15px; width:calc(100% - 30px); transform: translate(0, -50%); background-color: #fff; border-radius: 7px; text-align: left; z-index: 910;}
.layerPop .inBox .title{ position: relative; height: 60px; line-height: 60px; font-size: 18px; font-family: 'Bold', sans-serif; padding: 0 0 0 15px;}
.layerPop .inBox .close{ position: absolute; top:15px; right: 15px; font-size: 20px;}
.layerPop .inBox .contents{ max-height: calc(100vh - 90px) !important; overflow-y: auto; padding: 0 15px 0 15px;}
@keyframes layerpop {
    0% {top: 70%; opacity: 0;}
    100% {top: 50%; opacity: 1;}
}

.topArwNavi .top{ position: fixed; top:0; left: 0; width:100%; height:60px; line-height: 60px; font-size: 20px; padding: 0 0 0 15px; font-family: 'Bold', sans-serif; z-index: 700;}
.topArwNavi .top i{ margin: 0 10px 0 0; font-size: 18px;}
.topArwNavi .blank{ height: 60px;}

.registerWrap{ text-align: center;}
.registerWrap .box{ position: relative; height: calc(100vh - 331px); min-height: 320px;}
.registerWrap .box .inBox{ position: absolute; top:calc(50% - 150px); left: 0; width:100%; height: 300px;}
.registerWrap .box .txt1{ animation: txt1 3s forwards; position: absolute; top:60px; left: 0; width:100%; font-size: 30px; font-family: 'Bold', sans-serif; opacity: 0;}
@keyframes txt1 {
    0% {top: 60px; opacity: 0;}
    40% {top: 30px; opacity: 1;}
    100% {top: 30px; opacity: 1;}
}
.registerWrap .box .txt2{ animation: txt2 3s forwards; position: absolute; top:110px; left: 0; width:100%; font-size: 26px; font-family: 'Light', sans-serif; opacity: 0;}
@keyframes txt2 {
    0% {top: 110px; opacity: 0;}
    20% {top: 110px; opacity: 0;}
    60% {top: 80px; opacity: 1;}
    100% {top: 80px; opacity: 1;}
}
.registerWrap .box .txt2 span{ font-family: 'Bold', sans-serif; color: #6F2089;}
.registerWrap .box .txt3{ animation: txt3 3s forwards; position: absolute; top:140px; left: 0; width:100%; font-size: 26px; font-family: 'Light', sans-serif; opacity: 0;}
@keyframes txt3 {
    0% {top: 140px; opacity: 0;}
    20% {top: 140px; opacity: 0;}
    60% {top: 110px; opacity: 1;}
    100% {top: 110px; opacity: 1;}
}
.registerWrap .box .ico{ animation: img1 3s forwards; position: absolute; top:210px; left: 0; width:100%; opacity: 0;}
.registerWrap .box .ico img{ height: 100px;}
@keyframes img1 {
    0% {top: 210px; opacity: 0;}
    40% {top: 210px; opacity: 0;}
    80% {top: 180px; opacity: 1;}
    100% {top: 180px; opacity: 1;}
}
.registerWrap .login{ text-align: right; padding: 0 15px 30px 0; color: #999; font-size: 15px;}
.registerWrap .login .btn{ font-family: 'Bold', sans-serif; color: #6F2089; padding: 3px 0 0 0; text-decoration: underline;}
.registerWrap .agree{ text-align: left; padding: 10px 15px 20px 15px; font-size: 15px;}
.registerWrap .agree ul{ position: relative; margin-bottom: 15px;}
.registerWrap .agree ul .arw{ position: absolute; top:0; right: 0; color: #ccc;}
.btnType{ padding: 0 15px 5px 15px; text-align: center;}
.btnType ul{ position: relative; height: 42px; line-height: 42px; border-radius: 5px; background-color: #ccc; color: #fff; margin-bottom: 10px; font-family: 'Bold', sans-serif;}
.btnType ul img{ position: absolute; top:14px; left: 14px; height: 14px;}
.btnType ul.kakao{ background-color: #FEE500; color: #3a1d1d;}
.btnType ul.naver{ background-color: #03C75A;}
.btnType ul.withpet{ background-color: #6F2089;}

.registerForm{}
.registerForm .lottie{ width: 150px; margin: 0 auto;}
.registerForm .title{ text-align: center; font-size: 26px; font-family: 'Bold', sans-serif; padding: 0 0 0 0; letter-spacing: -1px;}
.formDefault{ padding: 50px 15px 0 15px;}
.formDefault ul{ position:relative; margin: 0 0 15px 0;}

.registerComplete{ text-align: center;}
.registerComplete .box{ position: fixed; top:50%; left: 50%; transform: translate(-50%, -60%); width:100%;}
.registerComplete .lottie{ width:200px; margin: 0 auto;}
.registerComplete .text{ font-size: 22px; letter-spacing: -1px; padding: 30px 0 30px 0;}
.registerComplete .text ul:nth-child(1){ font-family: 'Bold', sans-serif; font-size: 26px; padding:0 0 10px 0;}
.registerComplete .text ul:nth-child(2) span{ font-family: 'Bold', sans-serif; color: #6F2089;}
.registerComplete .cover{animation: complete 2s forwards; position: fixed; top:0; left: 0; width:100%; height: 100%; z-index: 1000;}
.registerComplete .cover div{ position: absolute; top:50%; left:50%; transform: translate(-50%, -50%); width:100%;}
@keyframes complete {
    0% { opacity: 1;}
    80% { opacity: 1;}
    100% { opacity: 0; display: none;}
}

.loginWrap .box{ position: relative; height: calc(100vh - 170px);}
.loginWrap .box .img{ text-align: center; padding: 30px 0 0 0;}
.loginWrap .box .img img{ height: 100px;}
.loginWrap .box .text{ text-align: center; font-size: 26px; font-family: 'Bold', sans-serif; padding: 20px 0 0 0;}

.mainMore{ position: absolute; top:15px; right: 15px; color: #ccc; font-size: 20px; z-index: 100;}
.mainWeather .ico{ position: absolute; top:5px; right: 10px;}
.mainWeather .info{ display: flex; align-items: center; margin: 10px 0 0 0; font-size: 15px;}
.mainWeather .info i{ color: #ccc; font-size: 18px; margin: 0 5px 0 0;}
.mainWeather .info .temperature{ font-family: 'Bold', sans-serif; font-size: 30px; margin: 0 0 0 10px;}
.mainWeather .dust{ display: flex; background-color: #fafafa; border-radius: 10px; padding:15px 0 15px 0; margin: 15px 0 0 0;}
.mainWeather .dust ul{ width:100%; text-align: center; color: #888;}
.mainWeather .dust ul li.state{ display:inline-table; background-color: #ccc; width:50px; height: 50px; line-height: 50px; border-radius: 50px; color: #fff; font-size: 14px;}
.mainWeather .dust ul li.state.good{ background-color: #1c8bf3;}
.mainWeather .dust ul li.state.normal{ background-color: #0aa953;}
.mainWeather .dust ul li.state.bad{ background-color: #f36919;}
.mainWeather .dust ul li.state.worst{ background-color: #f34545;}
.mainWeather .dust ul li.type{ padding: 10px 0 0 0; font-family: 'Light', sans-serif}

.mainPetInfo .pet{ position: relative; padding-bottom: 25px;}
.mainPetInfo .pet .thumb{ position: relative; width:60px; height: 60px; border-radius: 60px; background-color: #f7f7f7; overflow: hidden;}
.mainPetInfo .pet .thumb img{ position: absolute; top:0; left: 0; width:100%; height: 100%; object-fit: cover;}
.mainPetInfo .pet .profile{ position: absolute; top:10px; left: 75px; display: flex; align-items: center;}
.mainPetInfo .pet .profile .age{ margin: 0 0 0 8px; color: #999;}
.mainPetInfo .pet .date{ position: absolute; top:36px; left: 75px; color: #999;}
.mainPetInfo .pet .date span{ margin: 0 0 0 5px;}
.mainPetInfo .pet .info{ display: flex; margin: 15px 0 0 0;}
.mainPetInfo .pet .info li{ width:100%; text-align: center; border: solid 1px #eee; border-radius: 10px; overflow: hidden;}
.mainPetInfo .pet .info li:nth-child(2){ margin: 0 5px 0 5px;}
.mainPetInfo .pet .info li dl:nth-child(1){ background-color: #fafafa; padding: 8px 0 8px 0; margin-bottom: 10px}
.mainPetInfo .pet .info li dl:nth-child(1) div{ width:18px; height: 18px; margin: 0 auto;}
.mainPetInfo .pet .info li dl:nth-child(2){ color: #999;}
.mainPetInfo .pet .info li dl:nth-child(3){ padding: 5px 0 10px 0; font-size: 15px; font-family: 'Bold', sans-serif;}

.mainDiary .list{ padding: 5px 0 0 0;}
.mainDiary .list ul{ position: relative; margin: 10px 0 0 0; background-color: #fafafa; height: 56px; border-radius: 56px;}
.mainDiary .list ul li.thumb{ position: absolute; top:0; left: 0; width:56px; height: 56px; border-radius: 56px; overflow: hidden;}
.mainDiary .list ul li.thumb .ico{ position: absolute; top:calc(50% - 10px); left: calc(50% - 10px); width:20px; height: 20px;}
.mainDiary .list ul li.thumb img{ position: absolute; top:0; left: 0; width:100%; height: 100%; object-fit: cover;}
.mainDiary .list ul li.title{ margin: 0 0 0 70px; font-size: 15px; padding: 12px 6px 0 0; overflow: hidden; display: -webkit-box;  -webkit-box-orient: vertical; word-break: break-all; -webkit-line-clamp: 1; text-overflow: ellipsis;}
.mainDiary .list ul li.date{ margin: 0 0 0 70px; color: #999;}

.mainNotice .tit{ position: absolute; top:15px; left: 15px;}
.mainNotice .list{ margin: 0 10px 0 50px; color: #888; height: 20px; line-height: 20px;}
.mainNotice .list li{ overflow: hidden; display: -webkit-box;  -webkit-box-orient: vertical; word-break: break-all; -webkit-line-clamp: 1; text-overflow: ellipsis;}

.noneBox{ text-align: center; padding: 30px 0 30px 0;}
.noneBox .txt{ color: #999; padding: 0 0 20px 0; font-size: 15px;}
.noneBox .btn{ display: inline-table; height: 40px; line-height: 40px; border-radius: 5px; background-color:#6F2089; color: #fff; padding: 0 15px 0 15px;}


















